import { useMemo } from 'react';

import { applicationSettings } from '~/shared/application.settings';
import { useIntl } from 'gatsby-plugin-intl';

export const useGetLinkRel = (link) => {
  const sourceUrl = applicationSettings.SITE_DOMAIN;
  const isExternal = useMemo(() => !link.startsWith(sourceUrl), [link]);
  const { locale } = useIntl();

  const transformUrl = (url, locale) => {
    const regex = new RegExp(`^(?:https?:\\/\\/[^/]+)?(?:\\/${locale}(?:\\/app)?)?\\/`);
    return url.replace(regex, '/');
  };

  const pingaAppLink = useMemo(() => transformUrl(link, locale), [link, locale]);

  return {
    rel: isExternal ? 'nofollow' : 'follow',
    isExternal: isExternal,
    url: isExternal ? link : pingaAppLink,
    target: isExternal ? '_blank' : '_self',
  };
};
