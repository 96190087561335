import React from 'react';

import { useGetLinkRel } from '~/shared';
import { ExternalLinkComponent } from '~/shared/components';
import clsx from 'clsx';
import { Link } from 'gatsby-plugin-intl';

export const RelFollowLink = ({ to, children, className = null }) => {
  const { rel, isExternal, url, target } = useGetLinkRel(to);
  const LinkComponent = !isExternal ? Link : ExternalLinkComponent;
  return (
    <LinkComponent to={url} target={target} className={clsx(className ? className : '')} rel={rel}>
      {children}
    </LinkComponent>
  );
};
